<script>
import {mapGetters, mapMutations} from "vuex";
import GameMixin from '@/mixins/GameMixin';
import {debounce} from "@/utils/debounce";

export default {
  name:"PlayerWrapper",
  mixins: [GameMixin],
  props: {
    position: {
      type: Number,
      required: true,
    }
  },
  computed: {
    ...mapGetters('game', ['myInfo', 'roomInfo', 'gameInfo', 'playersInfo']),
    player() {
      const positionIndex = this.position - 1;
      return this.playersInfo.find(p => p.idx === positionIndex);
    },
    isSeated() {
      return this.myInfo.idx !== -1;
    },
    me() {
      if (!this.player) {
        return false;
      }

      if (this.myInfo.idx === -1 || this.player.idx === -1) {
        return false;
      }

      return this.myInfo.idx === this.player.idx;
    },
    // 방 최대 인원
    maxPlayerLimit() {
      return this.gameInfo.playerCnt || 2;
    },
    middlePosition() {
      return Math.floor(this.maxPlayerLimit / 2) + 1;
    },
    isObserver() {
      return this.myInfo && this.myInfo.idx === -1;
    },
    seatNumber() {
      if (!this.isObserver) {
        return this.myInfo.idx + 1;
      }

      return this.position;
    },
    /**
     * 포지션 보정값
     */
    positionOffset() {
      if (this.isObserver) {
        return 0;
      }

      return this.middlePosition - this.seatNumber;
    },
    // 출력 포지션
    displayedPosition() {
      return this.calculateDisplayPosition(this.position + this.positionOffset);
    },
    classList() {
      const classList = {
        empty_seat: !this.player,
        game_player: !!this.player,
        game_user: this.me,
      };
      classList[`seat0${this.displayedPosition}`] = true;

      return classList;
    },
  },
  watch: {
    player() {
      this.setPlayerSeatPosition();
    }
  },
  methods: {
    ...mapMutations('game', {
      __setPlayerSeatPosition: 'SET_PLAYER_SEAT_POSITION'
    }),
    setPlayerSeatPosition() {
      this.$nextTick(() => {
        this.__setPlayerSeatPosition({
          playerIndex: this.player ? this.player.idx : -1,
          playerPosition: `0${this.displayedPosition}`,
          playerRef: this.$refs.player
        });
      });
    },
    calculateDisplayPosition(position) {
      return ((position - 1 + this.maxPlayerLimit) % this.maxPlayerLimit) + 1;
    },
    buyinAction: debounce(function () {
      if (this.isSeated || this.player) {
        return;
      }
      //
      // this.$store.dispatch(
      //   'user/reserveSeatPosition',
      //   {
      //     $socket: this.$socket,
      //     roomSn: this.roomInfo.sn,
      //     data: {
      //       position: this.position - 1
      //     },
      //   }
      // ).catch(reason => {
      //   const errorMessage = this.errorProc(`seat`, reason);
      //   errorMessage && alert(errorMessage);
      // }).finally(() => {
      this.$emit('buyin', {position: this.position});
      // });
    }, 500, {leading: true}),
  },
  mounted() {
    this.setPlayerSeatPosition();
  }
}
</script>

<template>
  <div
    v-show="!isSeated || me || !me && player"
    ref="player"
    :class="classList"
    @click.prevent.stop="buyinAction"
  >
    <slot :empty="!player" :player="player" :me="me"></slot>
  </div>
</template>
